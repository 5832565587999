// from snake case to human readable
export function snakeToHuman(str: string) {
  return str.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
}
export function getShortDescription(text: string, maxLength: number = 176): string {
  if (text.length <= maxLength) {
    return text;
  }

  const truncatedText = text.slice(0, maxLength);
  const lastSpaceIndex = truncatedText.lastIndexOf(' ');

  if (lastSpaceIndex === -1) {
    return truncatedText + '...';
  }

  return truncatedText.slice(0, lastSpaceIndex) + '...';
}
